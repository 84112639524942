<template>
  <div>
    <div class="container">
      <section class="my-5 header-section">
        <!--
        <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FMawKunInternationalEducationGroupMyanmar%2Fvideos%2F598774699276527%2F&show_text=true&width=560&t=0" width="560" height="429" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" ></iframe>

        <div v-html="testframe" data-show-text="true"></div>
        -->
        <div>
          <div class="d-flex flex-column flex-md-row align-items-center justify-content-center g-3">
            <img src="./../assets/img/abschool_top.jpg" class="w-100 header-section-img" alt="">
            <template v-if="font_type == 1">
              <div class="p-4 header-section-content">
                <h1 class="mb-5" style="font-size:30px; color:goldenrod">ABOUT SHWE MAW KUN</h1>
                <p style="text-align:justify">
                  Established in 2015, Shwe Maw Kun is a renowned institution in Myanmar that places a strong emphasis on providing high-quality education through a comprehensive curriculum designed by the Ministry of Education. With an unwavering commitment to nurturing well-rounded individuals, we offer a diverse range of educational and professional development  programmes tailored to meet the needs of our students and international curriculum instructors. Our esteemed faculty, in collaboration with the Shwe Maw Kun, tirelessly strive to create an enriching and stimulating learning environment.
                </p>
                <p style="text-align:justify">
                  Shwe Maw Kun Private School is part of Maw Kun International Education Group, it is also affiliated with Help Academy, which is renowned in Asia Pacific for A-Level education in Malaysia and produces most of its students who go on to study at top universities around the world. Through various initiatives such as A-Level courses, the Weekend International Programme, extracurricular activities, and community engagement projects, our aim is to foster holistic growth in our students. We deeply value the importance of physical education and consistently promote a healthy lifestyle. Furthermore, as a trusted British Council Partner, Shwe Maw Kun stands as a premier institution in the region, providing Pearson Edexcel qualifications, including the Early Years Centre, and Cambridge English Assessment services, ensuring adherence to globally recognised standard with affordable tuition fees.

                </p>
              </div>
            </template>
            <template v-if="font_type == 2">
              <div class="p-4 header-section-content">
                <h1 class="mb-5" style="font-size:30px; line-height: 48px; color:goldenrod">ရွှေမော်ကွန်း ကျောင်းအကြောင်း</h1>
                <p style="text-align:justify;line-height: 30px;">
                  ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းကို ၂၀၁၅ခုနှစ်တွင် နိုင်ငံတကာသင်ရိုးနှင့်ပြည်ထောင်စုမြန်မာနိုင်ငံတော်
                  ပညာရေးဝန်ကြီးဌာန၏ အခြေခံပညာသင်ရိုးတို့ကို သင့်တင့်မျှတသော နှုန်းထားဖြင့်
                  ကျောင်းသား/သူများတက်ရောက်နိုင်ရန်
                  ရည်ရွယ်ချက်ဖြင့်စတင်ဖွင့်လှစ်ခဲ့ပါသည်။
                  ပညာရေးဝန်ကြီးဌာနတွင် ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းအမည်ဖြင့် မှတ်ပုံတင်ထားရှိပြီး
                  ကိုယ်ပိုင်ကျောင်းလိုင်စင်ရရှိထားကာ
                  မော်ကွန်းနိုင်ငံတကာပညာရေးလုပ်ငန်းစုအမည်ဖြင့်ကုမ္ပဏီလိုင်စင်ရရှိထားပါသည်။
                  ယခုအခါ ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းတွင် မူလတန်းကြိုအတန်းများ၊ မူလတန်း၊ အလယ်တန်း၊ Weekend International
                  Programme နှင့် လူကြီးများအတွက် ခေါင်းဆောင်မှုသင်တန်းများ၊ စွမ်းရည်မြှင့်တင်မှုသင်တန်းတို့ကိုပါ
                  ပို့ချပေးလျက်ရှိပါသည်။
                </p>
                <p style="text-align:justify;line-height: 30px;">
                  ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းသည် မလေးရှားနိုင်ငံရှိ A Level သင်ကြားရေး၌ အာရှပစိဖိတ်တွင်
                  ထင်ရှားကျော်ကြားပြီး ကမ္ဘာ့နိုင်ငံများရှိထိပ်သီးတက္ကသိုလ်များသို့ တက်ရောက်ပညာသင်ကြားခွင့်ရသော
                  ကျောင်းသားအများစုကို ပျိုးထောင်ပေးသည့် Help Academy နှင့် ချိတ်ဆက်ထားပါသည်။
                  ထို့အပြင် ရွှေမော်ကွန်းကိုယ်ပိုင်ကျောင်းသည် တောင်ကြီးမြို့၏ပထမဦးဆုံး British Council Partner
                  ကျောင်းဖြစ်ခြင်း၊ အင်္ဂလန်နိုင်ငံရှိနာမည်ကြီးစာစစ်ဌာနတစ်ခုဖြစ်သော Pearson Edexcel၏
                  အသိအမှတ်ပြုဌာနဖြစ်ခြင်း၊
                  Pearson Edexcel၏ အသိအမှတ်ပြု Early Years Centre ဖြစ်ခြင်းနှင့် Cambridge English Assessment ၏
                  စာစစ်ဌာနဖြစ်ခြင်းတို့ဖြစ်သည်။
                </p>
              </div>
            </template>
          </div>
        </div>
      </section>

      <section class="smk_responsive mb-5">
        <div class="row gx-0 gy-3 gy-sm-0">
          <div class="col shadow" style="border: 0px;
                          border-right: 2px black;
                          border-style: solid;">
            <div v-if="font_type == 1" class="section2_divleft ">
              <h2 class="text-center">Educational Excellence </h2>
              <p style=" white-space: pre-wrap">
                <ul style=" text-align: justify;">
                  <li>
                    <p>
                      To become a nationally and internationally-recognised school providing quality and affordable education to pupils to develop confidence, competence and to prepare each pupil for a socially responsible and compassionate citizen. 

                    </p>
                  </li>
                  <li>
                    <p>
                      We actively champion and nurture gifted individuals who exhibit exceptional abilities, immense potential, and remarkable leadership qualities, empowering them to make significant contributions to the educational progress and overall advancement of our nation.

                    </p>
                  </li>
                </ul>
              </p>
              
            </div>
            <div v-if="font_type == 2" class="section2_divleft">
              <h3 class="mb-5">ပညာရေး မျှော်မှန်းချက်</h3>
              <ul style="list-style-type: square;text-align: start;">
                <li>
                  <p>
                    သင့်တင့်မျှတသောနှုန်းထားဖြင့် အရည်အသွေးပြည့်မှီသော ပြည်တွင်းပြည်ပ
                    အသိအမှတ်ပြုကျောင်းတစ်ကျောင်းဖြစ်လာစေရန်။
                  </p>
                </li>
                <li>
                  <p>
                    အရည်အချင်းရှိသော၊ မိမိကိုယ်ကို ယုံကြည်မှုရှိသော ၊ တာဝန်ယူမှု တာဝန်ခံမှုရှိသော ၊ စာနာတတ်သော
                    အနာဂတ်နိုင်ငံ့သားကောင်းများ မွေးထုတ်ပေးနိုင်ရန်။
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col shadow" style="border: 0px;
                                                        border-right: 2px black;
                                                        border-style: solid;">
            <div v-if="font_type == 1" class="section2_divcenter">
              <h2></h2>
              <h2>Educational Purpose</h2>
              <p style=" white-space: pre-wrap">
              <ul style=" text-align: justify;">
                <li>
                  Enabling the empowerment of individuals and communities possessing knowledge and skills, as well as those who demonstrate capability and enthusiasm, to actively participate in decision-making processes and governance. This endeavor seeks to foster a democratic society that promotes accountability, transparency, productive and compassionate citizens. 
<br>

                </li>
                <li>
                  Cultivate a dynamic educational environment that encourages active engagement in intellectual exploration, nurtures the development of critical thinking skills, and
promote the acquisition and wide dissemination of substantial knowledge and skills by enabling the fostering of critical thinking, nurturing leadership qualities, and advancing the cultivation of a literate society. 
<br>

                </li>
                <li>
                  To offer the best possible well-rounded quality education incorporated with nationally and internationally recognized qualification with relatively reasonable fees in the region.
<br>

                </li>
              </ul>
              </p>
            </div>
            <div v-if="font_type == 2" class="section2_divcenter">
              <h3 class="mb-5">ပညာရေး ရည်မှန်းချက်</h3>
              <ul style="list-style-type: square;text-align: start;">
                <li>
                  <p>
                    ကျောင်းသား/ကျောင်းသူတစ်ယောက်စီတိုင်းကို အရည်အချင်းရှိသော၊ မိမိကိုယ်ကို ယုံကြည်မှုရှိသော ၊ တာဝန်ယူမှု
                    တာဝန်ခံမှုရှိသော ၊ စာနာတတ်သော အနာဂတ်နိုင်ငံ့သားကောင်းများ မွေးထုတ်ပေးနိုင်ရန်။
                  </p>
                </li>
                <li>
                  <p>
                    ပြောင်းလဲလာသည့်ပညာခေတ်တွင် ကျောင်းသား/သူများအတွက် လုံခြုံမှုအပြည့်ရှိပြီး လှုံ့ဆော်မှုအားကောင်းသော
                    သင်ကြားသင်ယူမှုရပ်ဝန်းကောင်းလေးဖန်တီးပေးခြင်းဖြင့် စိန်ခေါ်မှုများကိုရင်ဆိုင်ကျော်ဖြတ်နိုင်သော
                    ကျောင်းသား/သူလေးများပေါ်ပေါက်လာစေရန်။
                  </p>
                </li>
                <li>
                  <p>
                    သင့်တင့်မျှတသောနှုန်းထားဖြင့် ပြည်တွင်းပြည်ပအသိအမှတ်ပြု ကျောင်းတစ်ကျောင်းဖြစ်လာစေပြီး
                    အရည်အသွေးပြည့်မှီသောပညာရေးကို လက်ဆင့်ကမ်းပေးနိုင်ရန်။
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col shadow" style="">
            <div v-if="font_type == 1" class="section2_divright ">
              <h2 class="text-center">Educational Academic System
</h2>
              <p>
              <ul style=" text-align: justify;">
                <li>
                 With a collaborative approach involving international educational institutions, the aim is to establish and advance a comprehensive academic system in Myanmar. This system will embrace two fundamental pillars of exceptional education, prioritizing equal opportunities and cultivating a culture of excellence. This endeavor entails enhancing teaching methodologies and implementing robust professional development programs to elevate educational standards and foster a climate of educational distinction.
 <br>

                </li>
                <li>
                  Enhance the educational sector's abilities within local and global contexts by advocating for and endorsing two exemplary educational models.
<br>

                </li>
                <li>
                  Nurture exceptional talent within the field of education through the strategic organization of competitions and the creation of a stimulating academic environment that encourages creativity and innovation.
<br>

                </li>
                <li>
                 
Facilitate the acquisition and wide dissemination of knowledge by providing comprehensive educational resources and empowering individuals with diverse opportunities for personal growth, while promoting a culture of responsibility and exemplary leadership among students and stakeholders in the 21st century.
<br>

                </li>
              </ul>
              </p>
            </div>
            <div v-if="font_type == 2" class="section2_divright ">

              <h3 class="mb-5">အနာဂတ် ပညာရေးပန်းတိုင်</h3>
              <ul style="list-style-type: square;text-align: start;">
                <li>
                  <p>
                    အရည်အချင်းပြည့်မှီသော ဆရာ ဆရာမကောင်းများကို ခန့်အပ်ထားနိုင်ပြီး ပြည့်စုံကောင်းမွန်သော ပညာရေးကို
                    ဖန်တီးပေးနိုင်ရန်။
                  </p>
                </li>
                <li>
                  <p>
                    နိုင်ငံတကာသင်ရိုးနှင့် ပြည်ထောင်စုမြန်မာနိုင်ငံ ပညာရေးဝန်ကြီးဌာနမှ ပြဌာန်းထားသော အခြေခံပညာသင်ရိုး
                    ၂ခုစလုံးအတွက် သင်ကြားရေးနည်းလမ်းကောင်းများကို စဉ်ဆက်မပြတ် ပံ့ပိုးပေးနိုင်ရန်။ </p>
                </li>
                <li>
                  <p>
                    နိုင်ငံတကာသင်ရိုးနှင့် ပြည်ထောင်စုမြန်မာနိုင်ငံ ပညာရေးဝန်ကြီးဌာနမှ ပြဌာန်းထားသော
                    အခြေခံပညာသင်ရိုးတို့ကို
                    ပူးတွဲသင်ကြားပေး၍ စိန်ခေါ်မှုများပြီး
                    တိုးတက်ပြောင်းလဲမှုမြန်ဆန်သောပညာခေတ်တွင် ကလေးများအနေဖြင့် ပညာရေးဖြင့် ပိုမိုကောင်းမွန်သောဘဝများကို
                    ဖန်တီးနိုင်ရန်။
                  </p>
                </li>
                <li>
                  <p>
                    စဉ်ဆက်မပြတ်လေ့လာသင်ယူနိုင်မှုကို မြှင့်တင်ပေးနိုင်ပြီး ကျောင်းသား/သူများအတွက် ၂၁ရာစုခေတ်တွင်လိုအပ်သော
                    ပညာအရည်အသွေးများကို ဖန်တီးပေးနိုင်ရန်နှင့် တာဝန်ယူမှု၊ တာဝန်ခံမှုရှိသော စာနာတတ်သော
                    နိုင်ငံသားကောင်းလေးများပေါ်ထွန်းလာစေရန်။
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section style="background-color: purple;">
        <section class="">
          <ul class="tabs-controls">
            <li class="tabs-controls__item">
              <a href="#" class="tabs-controls__link tabs-controls__link--active" data-id="1">
                T
              </a>
            </li>
            <li class="tabs-controls__item">
              <a href="#" class="tabs-controls__link" data-id="2">
                R
              </a>
            </li>
            <li class="tabs-controls__item">
              <a href="#" class="tabs-controls__link" data-id="3">
                U
              </a>
            </li>
            <li class="tabs-controls__item">
              <a href="#" class="tabs-controls__link" data-id="4">
                S
              </a>
            </li>
            <li class="tabs-controls__item">
              <a href="#" class="tabs-controls__link" data-id="5">
                T
              </a>
            </li>

          </ul>
        </section>
        <section class="cards-container  smk_responsive2">
          <!--    Teach-->
          <div v-if="font_type == 1" class="card card--current" id="1">
            <h1><span style="color:purple;">T</span>EACH</h1>
            <p style="font-size:20px;">
              <br>
              inspiring teachers to engage in continual learning for valuable teaching
            </p>
          </div>
          <div v-if="font_type == 2" class="card card--current" id="1">
            <h1><span style="color:purple;">T</span> - Teach - သင်ကြားသင်ယူမှု</h1>
            <p style="font-size:20px;"><br>
              တန်ဖိုးရှိသော သင်ကြားမှုများအတွက် စဉ်ဆက်မပြတ် လေ့လာသင်ယူမှုတွင်ပါဝင်ရန် ဆရာ ဆရာမများအား
              လှုံ့ဆော်ပေးနိုင်ရန်။
            </p>
          </div>

          <!--    Respect-->
          <div v-if="font_type == 1" class="card" id="2">
            <h1><span style="color:purple;">R</span>ESPECT</h1>
            <p style="font-size:20px;">
              <br>
              respect for self and others
            </p>
          </div>
          <div v-if="font_type == 2" class="card" id="2">
            <h1><span style="color:purple;">R</span> - Respect</h1>
            <p style="font-size:20px;">
              <br>
              တစ်ဦးနှင့် တစ်ဦး အပြန်အလှန်လေးစားမှု - ကိုယ့်ကိုယ်ကိုရော သူတစ်ပါးကိုပါလေးစားမှုရှိရန်။
            </p>
          </div>

          <!--    unique-->
          <div v-if="font_type == 1" class="card" id="3">
            <h1><span style="color:purple;">U</span>NIQUE</h1>
            <p style="font-size:20px;">
              <br>
              the development of unique curriculum to deliver academic success and build self-worth to be a compassionate
              and responsible citizen, and to the bond-building among teachers, pupils and parents for higher education.
            </p>
          </div>
          <div v-if="font_type == 2" class="card" id="3">
            <h1><span style="color:purple;">U</span> - Unique</h1>
            <p style="font-size:20px;">
              <br>
              မိဘ၊ဆရာ၊ကျောင်းသား သုံးဦး သုံးဖလှယ် ပူးပေါင်းပြီး စာနာနားလည်တတ်သော၊ တာဝန်သိသော၊ ထူးချွန်ထက်မြက်သော
              မိမိကိုယ်မိမိတန်ဖိုးထားတတ်သော နိုင်ငံသားကောင်းများပေါ်ထွန်းလာစေရန်နှင့် အဆင့်မြင့်ပညာရေးအတွက်
              ထူးခြားသစ်လွင်သော
              သင်ရိုးညွှန်းတမ်းများ ဖော်ဆောင်နိုင်စေရန်။
            </p>
          </div>
          <!--    Success-->
          <div v-if="font_type == 1" class="card" id="4">
            <h1><span style="color:purple;">S</span>UCCESS</h1>
            <p style="font-size:20px;">
              <br>
              the professional growth of teachers, pupils and school
              success
            </p>
          </div>
          <div v-if="font_type == 2" class="card" id="4">
            <h1><span style="color:purple;">S</span> - Success</h1>
            <p style="font-size:20px;">
              <br>
              ဆရာ ဆရာမများ နှင့် ကျောင်းသား/သူများ၏ ကျွမ်းကျင်တိုးတက်မှုနှင့် အောင်မြင်သောကျောင်းအဖြစ်ရပ်တည်နိုင်စေရန်။

            </p>
          </div>

          <!--    Talent-->
          <div v-if="font_type == 1" class="card" id="5">
            <h1><span style="color:purple;">T</span>ALENT</h1>
            <p style="font-size:20px;">
              <br>
              discover the natural talent, skills and aptitude of the pupils and teachers
            </p>
          </div>
          <div v-if="font_type == 2" class="card" id="5">
            <h1><span style="color:purple;">T</span> - Talent</h1>
            <p style="font-size:20px;">
              <br>
              ကျောင်းသား/သူနှင့် ဆရာ ဆရာမများ၏ ပင်ကိုယ်စွမ်းရည်နှင့် အရည်အချင်းများကို ဖော်ထုတ်နိုင်စေရန်။
            </p>
          </div>

        </section>
      </section>

      <Messages>
        
      </Messages>

      <section id="examcenter" tabindex="0" class="mb-5" style="width:100%; height:100%;">
        <div class="shadow p-4">
          <h2 class="m-3"> <b>INTERNATIONAL OLYMPIAD EXAM</b> </h2>
          <div class="row mb-5 align-items-center">
            <div class="col-6 col-md-4">
              <img src="../assets/img/timo.jpg" class="exam-image" alt="">
            </div>
            <div class="col-6 col-md-4">
              <img src="../assets/img/south_asia_math.jpg" class="exam-image" alt="">
            </div>
            <div class="col-6 col-md-4">
              <img src="../assets/img/hk_math.jpg" class="exam-image" alt="">
            </div>
            <div class="col-6 col-md-4">
              <img src="../assets/img/hippo2023.jpg" class="exam-image" slt="">
            </div>
            <div class="col-6 col-md-4">
              <img src="../assets/img/hk_science.jpg" class="exam-image" alt="">
            </div>
            <div class="col-6 col-md-4">
              <img src="../assets/img/hk_international_computer.jpg" class="exam-image" alt="">
            </div>
          </div>
          <!--              <img style="width:100%; height:100%; object-fit: contain;" src="./../assets/img/ourpartner.png" alt="">-->
        </div>
      </section>

      <section id="parter" class="" style="width:100%; height:100%;">
        <div class="shadow mb-5 p-4">
          <h2 class="m-3"> <b>OUR ACCREDITATIONS</b> </h2>
          <img class="accerditations-image" src="./../assets/img/ourpartner2.png" alt="">
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import MV from '../views/vision_mission.vue'
import Messages from '@/components/About/Messages.vue'
export default {
  data() {
    return {
      testframe: "<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMawKunInternationalEducationGroupMyanmar%2Fposts%2Fpfbid0TictB8kXpSfGdK8C2r2BEJxSMqSYnByY51MZVgwPNFjdx1W5Et1AU4fkoUbLFs6Pl&show_text=true&width=500' width='500' height='500' style='border:none;overflow:hidden' scrolling='yes' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>"
    };
  },
  components: {
    MV,
    Messages
  },
  computed: {
    font_type() {
      return this.$store.state.font_type
    }
  },
  mounted() {


    //       $(document).ready(function() {

    // });
    $("#scrolldown").hide();
    $(document).ready(function () {
      $("#scrolldownbtn").click(function () {
        $("#scrolldown").slideToggle("slow", function () {
          // Animation complete.
        });
      });

      var oldId = null;

      $('.tabs-controls__link').click(function (e) {
        e.preventDefault();

        if ($(this).hasClass('tabs-controls__link--active')) {
          return false;
        }

        var currentId = parseInt($(this).data('id'), 10);
        $('.tabs-controls__link--active').removeClass('tabs-controls__link--active');
        $(this).addClass('tabs-controls__link--active');

        if (currentId < oldId) { // item is hidden
          var timing = $('.card.hidden').length * 100;
          $('.card').each(function (index) {
            if (index > (currentId - 1) || index == (currentId - 1)) {
              window.setTimeout(function () {
                $('.card').eq(index).removeClass('hidden');
              }, timing - (index * 100));
            }
          });
        } else {
          $('.card').each(function (index) {
            if (index < (currentId - 1)) {
              window.setTimeout(function () {
                $('.card').eq(index).addClass('hidden');
              }, index * 100);
            }
          });
        }

        oldId = currentId;
      });
    });
  }
}
</script>

<style lang="scss" scoped>
.header-section {
  p {
    font-size: 18px;

    @include breakpoint(large) {
      font-size: 20px;
    }
  }
}

.header-section-img {
  width: auto;
  height: 300px;

  @include breakpoint(medium) {
    height: 400px;
    width: 493px !important;
  }
}

.header-section-content {
  width: 100%;

  @include breakpoint(medium) {
    width: 800px;
  }
}

.exam-image {
  width: 100%;

  @include breakpoint(large) {
    width: 300px;
  }
}

.accerditations-image {
  height: 100px;
  object-fit: contain;

  @include breakpoint(large) {
    height: 300px;
  }
}


$itemsCount: 6; // Adjust if tabs number changes
// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

h1 {
  margin: 0;
  font-size: 30px;
  line-height: 1;
  color: rgb(0, 0, 0);
}

// Layout
.page {
  width: 100vw;
  height: 60vh;
  min-height: 700px;
  overflow: hidden;
}

// Tabs
.tabs-controls {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
  list-style-type: none;

  @include breakpoint(large) {
    padding: 50px 0 100px;
  }
}

.tabs-controls__link {
  position: relative;
  display: block;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;

  @include breakpoint(large) {
    padding: 16px 32px;
    font-size: 20px;
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 4px;
    background-color: #ebebeb;
    border-radius: 2px;
    margin: auto;
    content: '';
    transition: width 0.4s;
  }
}

.tabs-controls__link--active {
  &:after {
    width: 100%;
  }
}

.cards-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.card {
  position: absolute;
  width: 80%;
  height: 300px;
  background-color: gold;
  border: 1px solid #423E37;
  box-shadow: 9px 11px 3px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 40px;
  transition: transform 0.3s, opacity 0.2s;

  &.hidden {
    z-index: 100;
    background-color: #fff;
    color: #fff;
    opacity: 0;
    transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;

    h1 {
      color: #fff;
      transition: color 0.5s
    }

    @for $i from 1 through $itemsCount {
      &:nth-of-type(#{$i}) {
        transform: rotate(random(100) - 100 + deg) translateX((random(1) + 100) * 1%) translateY(random(60) * -1%) scale(random(10)*0.1) skewX(random(12) + deg) skewY(random(12) + deg);
      }
    }
  }

  @for $i from 1 through $itemsCount {
    $zIndexStartValue: $itemsCount +1; // we don't want an item with 0 index
    $xValue: ($i - 1) * 10px; // we want to start translating from 0
    $yValue: ($i - 1) * 10px; // we want to start translating from 0
    $zIndexValue: $itemsCount - $i; // reverse the z-index, so first item is on top

    &:nth-of-type(#{$i}) {
      z-index: $zIndexValue;
      transform: translateX(#{$xValue}) translateY(#{$yValue});
    }
  }
}

// Cards

@media (min-width:480px) {
  .cards-container {
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }

  .card {
    position: absolute;
    background-color: gold;
    border: 1px solid #423E37;
    box-shadow: 9px 11px 3px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 40px;
    transition: transform 0.3s, opacity 0.2s;

    &.hidden {
      z-index: 100;
      background-color: #fff;
      color: #fff;
      opacity: 0;
      transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;

      h1 {
        color: #fff;
        transition: color 0.5s
      }

      p {
        font-size: 18px;
      }

      @for $i from 1 through $itemsCount {
        &:nth-of-type(#{$i}) {
          transform: rotate(random(100) - 100 + deg) translateX((random(1) + 100) * 1%) translateY(random(60) * -1%) scale(random(10)*0.1) skewX(random(12) + deg) skewY(random(12) + deg);
        }
      }
    }

    @for $i from 1 through $itemsCount {
      $zIndexStartValue: $itemsCount +1; // we don't want an item with 0 index
      $xValue: ($i - 1) * 10px; // we want to start translating from 0
      $yValue: ($i - 1) * 10px; // we want to start translating from 0
      $zIndexValue: $itemsCount - $i; // reverse the z-index, so first item is on top

      &:nth-of-type(#{$i}) {
        z-index: $zIndexValue;
        transform: translateX(#{$xValue}) translateY(#{$yValue});
      }
    }
  }
}

@media (min-width:960px) {
  .cards-container {
    position: relative;
    z-index: 1;
    width: 650px;
    height: 400px;
    margin: 0 auto;
  }

  .card {
    position: absolute;
    width: 600px;
    height: 300px;
    background-color: gold;
    box-shadow: 9px 11px 3px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 40px;
    transition: transform 0.3s, opacity 0.2s;

    &.hidden {
      z-index: 100;
      background-color: #fff;
      color: #fff;
      opacity: 0;
      transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;

      h1 {
        color: #fff;
        transition: color 0.5s
      }

      @for $i from 1 through $itemsCount {
        &:nth-of-type(#{$i}) {
          transform: rotate(random(100) - 100 + deg) translateX((random(1) + 100) * 1%) translateY(random(60) * -1%) scale(random(10)*0.1) skewX(random(12) + deg) skewY(random(12) + deg);
        }
      }
    }

    @for $i from 1 through $itemsCount {
      $zIndexStartValue: $itemsCount +1; // we don't want an item with 0 index
      $xValue: ($i - 1) * 10px; // we want to start translating from 0
      $yValue: ($i - 1) * 10px; // we want to start translating from 0
      $zIndexValue: $itemsCount - $i; // reverse the z-index, so first item is on top

      &:nth-of-type(#{$i}) {
        z-index: $zIndexValue;
        transform: translateX(#{$xValue}) translateY(#{$yValue});
      }
    }
  }

}

@media (min-width:1025px) {
  .cards-container {

    position: relative;
    z-index: 1;
    width: 750px;
    height: 400px;
    margin: 0 auto;
  }

  .card {
    position: absolute;
    width: 700px;
    height: 300px;
    background-color: gold;
    border: 1px solid #423E37;
    box-shadow: 9px 11px 3px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 40px;

    transition: transform 0.3s, opacity 0.2s;

    &.hidden {
      z-index: 100;
      background-color: #fff;
      color: #fff;
      opacity: 0;

      transition: color 0.5s, background-color 0.5s, transform 1s, opacity 0.2s 0.4s;

      h1 {
        color: #fff;

        transition: color 0.5s
      }

      @for $i from 1 through $itemsCount {
        &:nth-of-type(#{$i}) {
          transform: rotate(random(100) - 100 + deg) translateX((random(1) + 100) * 1%) translateY(random(60) * -1%) scale(random(10)*0.1) skewX(random(12) + deg) skewY(random(12) + deg);
        }
      }
    }

    @for $i from 1 through $itemsCount {
      $zIndexStartValue: $itemsCount +1; // we don't want an item with 0 index
      $xValue: ($i - 1) * 10px; // we want to start translating from 0
      $yValue: ($i - 1) * 10px; // we want to start translating from 0
      $zIndexValue: $itemsCount - $i; // reverse the z-index, so first item is on top

      &:nth-of-type(#{$i}) {
        z-index: $zIndexValue;
        transform: translateX(#{$xValue}) translateY(#{$yValue});
      }
    }
  }


}

@media (min-width: 1200px) {
  .smk_responsive {
    zoom: .9;
    // transform: scale(0.5);
  }
}

@media (min-width: 1000px) AND (max-width: 1200px) {
  .smk_responsive {
    zoom: .8;
    // transform: scale(0.5);
  }
}

@media (min-width: 800px) AND (max-width: 1000px) {
  .smk_responsive {
    zoom: .6;
    // transform: scale(0.5);
  }
}

@media (min-width: 600px) AND (max-width: 800px) {
  .smk_responsive {
    zoom: .5;
    // transform: scale(0.5);
  }
}

@media (min-width: 500px) AND (max-width: 600px) {
  .smk_responsive {
    zoom: .4;
    // transform: scale(0.5);
  }
}

@media (max-width: 500px) {
  .smk_responsive {
    zoom: .3;
    // transform: scale(0.5);
  }
}

@media (min-width: 1200px) {
  .smk_responsive2 {
    zoom: 1;
    // transform: scale(0.5);
  }
}

@media (min-width: 1000px) AND (max-width: 1200px) {
  .smk_responsive2 {
    zoom: .9;
    // transform: scale(0.5);
  }
}

@media (min-width: 800px) AND (max-width: 1000px) {
  .smk_responsive2 {
    zoom: .8;
    // transform: scale(0.5);
  }
}

@media (min-width: 600px) AND (max-width: 800px) {
  .smk_responsive2 {
    zoom: .7;
    // transform: scale(0.5);
  }
}

@media (min-width: 500px) AND (max-width: 600px) {
  .smk_responsive2 {
    zoom: .6;
    // transform: scale(0.5);
  }
}

@media (max-width: 500px) {
  .smk_responsive2 {
    zoom: .5;
    // transform: scale(0.5);
  }
}

@media (min-width: 1200px) {
  .smk_responsive3 {
    zoom: 1;
    // transform: scale(0.5);
  }
}

@media (min-width: 1000px) AND (max-width: 1200px) {
  .smk_responsive3 {
    zoom: .8;
    // transform: scale(0.5);
  }
}

@media (min-width: 800px) AND (max-width: 1000px) {
  .smk_responsive3 {
    zoom: .7;
    // transform: scale(0.5);
  }
}

@media (min-width: 600px) AND (max-width: 800px) {
  .smk_responsive3 {
    zoom: .6;
    // transform: scale(0.5);
  }
}

@media (min-width: 500px) AND (max-width: 600px) {
  .smk_responsive3 {
    zoom: .5;
    // transform: scale(0.5);
  }
}

@media (max-width: 500px) {
  .smk_responsive3 {
    zoom: .4;
    // transform: scale(0.5);
  }
}
</style>