<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
      <div class="container">
        <h1 class="fw-bold">Course List</h1>
        <div class="d-flex justify-content-end mt-4">
          <router-link :to="{ name: `CoursesRegisterCreate` }" class="btn btn-primary">
            Create Course
          </router-link>
        </div>

        <div class="card mt-5">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <div class="d-flex align-items-end justify-content-start gap-3 mb-3 flex-wrap">
                  <div>
                    <select class="form-select" aria-label="Default select example" v-model="filter.type" @change="changeTypeOptions">
                      <option value="" disabled>Select Class Type</option>
                      <option :value="type" :key="index" v-for="(type, index) in classTypes">{{ type }}</option>
                    </select>
                  </div>
                  <template v-if="filter.type === 'weekday classes'">
                    <div>
                      <select class="form-select" aria-label="Default select example" v-model="filter.level">
                        <option value="" disabled>Select Year Level</option>
                        <option :value="level" :key="index" v-for="(level, index) in yearLevels['one']">{{ level }}</option>
                      </select>
                    </div>
                  </template>
                  <template v-if="filter.type === 'weekend classes & summer classes'">
                    <div>
                        <select class="form-select" aria-label="Default select example" v-model="filter.level">
                        <option value="" disabled>Select Year Level</option>
                        <option :value="level" :key="index" v-for="(level, index) in yearLevels['two']">{{ level }}</option>
                        </select>
                      </div>
                  </template>
                  <div class="d-flex gap-3">
                    <button class="btn btn-primary" @click="fetchCourses">Filter</button>
                    <button class="btn btn-primary" @click="reload">Reload</button>
                  </div>
                </div>

                <div class="row d-flex justify-content-center mt-5">
                  <div class="ms-1">
                    <div
                      class="container"
                      style="max-width:100%; max-height:1000px; overflow:scroll;"
                    >
                      <table class="table table-bordered table-hover">
                        <thead
                          style="position: sticky;top: 0;background-color: white;"
                        >
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Class Type</th>
                            <th scope="col">Year Level</th>
                            <th scope="col">Intake</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Order</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="isTableLoading">
                            <tr>
                              <td colspan="8">
                                <Loading />
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="courseList.length > 0">
                              <tr v-for="(course, index) in courseList" :key="course.id">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ course.class_type }}</td>
                                <td>{{ course.year_level }}</td>
                                <td>{{ course.intake }}</td>
                                <td>{{ course.fromMonth }}</td>
                                <td>{{ course.toMonth }}</td>
                                <td>{{ course.list_order }}</td>
                                <td class="text-nowrap">
                                  <router-link :to="`/admin/courses-register/${course.id}/edit`"
                                    class="btn btn-warning me-3">Edit</router-link>
                                  <button type="button" class="btn btn-danger" :disabled="isDeleting"
                                    @click="deleteCourse(course.id)">Delete</button>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr class="text-center">
                                <td colspan="8"> No course record exist!</td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </template>
</template>

<script>
import courseService from "@/services/courseApi";
import { cusFormatDate } from "@/utils/index";
import { courseConstant } from "@/constants";
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },
  data() {
    return {
      courseList: [],
      isLoading: true,
      isTableLoading: true,
      isDeleting: false,
      classTypes: courseConstant.classTypes,
      yearLevels: courseConstant.yearLevels,
      filter: {
        type: '',
        level: ''
      }
    }
  },
  methods: {
    cusFormatDate,
    changeTypeOptions() {
      this.filter.level = '';
    },

    async reload() {
      this.filter = {
        type: '',
        level: '',
      }
      await this.fetchCourses()
    },
    async fetchCourses() {
      this.isTableLoading = true;

      try {
        let payload = undefined
        if (this.filter.type && this.filter.level) {
          payload = {
            params: {
              type: this.filter.type,
              level: this.filter.level
            }
          }
        }
        let res = await courseService.getCourses(payload)
        console.log(res)
        this.courseList = res
      } catch (error) {
        this.courseList = [];
      } finally {
        this.isTableLoading = false;
      }
    },
    async deleteCourse(id) {
      let result = confirm("Want to delete?");
      if (result) {
        this.isDeleting = true
        try {
          let res = await courseService.deleteCourse({ id })
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Deleted',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
          this.fetchCourses()
        } catch (error) {
          console.log(error)
        } finally {
          this.isDeleting = false
        }
      }
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchCourses()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>