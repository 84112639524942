<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
      <div class="container">
        <h1 class="fw-bold">Contact Us List</h1>

        <div class="card mt-5">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <div class="row d-flex justify-content-center mt-5 ">
                  <div class="ms-1">
                    <div
                      class="container"
                      style="max-width:100%; max-height:1000px; overflow:scroll;"
                    >
                      <table class="table table-bordered table-hover">
                        <thead
                          style="position: sticky;top: 0;background-color: white;"
                        >
                          <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Content</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="isTableLoading">
                            <tr>
                              <td colspan="7">
                                <Loading />
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="dataList.length > 0">
                              <tr v-for="(data,index) in dataList" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ data.name }}</td>
                                <td>{{ data.phone }}</td>
                                <td>{{ data.email }}</td>
                                <td>{{ data.subject }}</td>
                                <td>{{ data.content }}</td>
                                <td>
                                  <button
                                    v-on:click="deleteRecord(data.id)"
                                    class="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td colspan="7">No record exist!</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import { DeviceUUID } from 'device-uuid';
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },

  data () {
    return {
      dataList: [],
      
      link:"https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      userID: '',
      localtoken: '',
      deviceID: '',
    };
  },

  methods: {
    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link+"/api/alreadyLogin", article)
        .then(response => {
          if(response.data.state=="failed") {
            window.location.assign("/login")
          } else if(response.data.state=="success") {
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios.post(this.link + "/api/deleteContactus/" + id)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link+"/api/contact_us")
       .then(response => {
          this.dataList = response.data;
        })
       .catch(error => {
          this.dataList = [];
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
  },

  async mounted () {
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();
      this.show();
    } catch (error) {
      console.error("error mounting contact us page: ", error)
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
