<template>
  <div class="p-3">
    <div
      class="modal fade"
      id="createmodal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Booking Slot</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row d-flex justify-content-center ">
              <div class="col-sm-10 ms-3">
                <div class="mt-5 ">
                  <form class="">
                    <div class="row ">
                      <div class="col ">
                        <div class="mb-3 row">
                          <label for="name" class="col-sm-2 col-form-label"
                            >Name</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              v-model="currentForm.name"
                              id="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label for="day_type" class="col-sm-2 col-form-label "
                            >Day TYpe</label
                          >
                          <div class="col-sm-8 ms-3">
                            <select
                              class="form-select"
                              aria-label="Default select"
                              id="day_type"
                              v-model="currentForm.day_type"
                            >
                              <option value="" disabled>Select Type</option>
                              <option
                                :value="index"
                                :key="index"
                                v-for="(type, index) in typeOptions"
                              >
                                {{ type }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Start Time</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="time"
                              class="form-control"
                              v-model="currentForm.start_time"
                              id="start_time"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >End Time</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="time"
                              class="form-control"
                              :min="currentForm.start_time"
                              v-model="currentForm.end_time"
                              id="end_time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-destory"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              class="d-flex justify-align-center btn btn-primary"
              @click="store"
            >
                <span
                  id="createbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isSaveBtnLoading"
                ></span>
              <span id="createbtntext" v-if="! isSaveBtnLoading">Save Record</span>
            </button>

            <button
              id="createclose"
              style="visibility: hidden;"
              type="button"
              data-bs-dismiss="modal"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h1 class="fw-bold">Booking Slot</h1>
      <div class="d-flex justify-content-end mt-4">
        <button
          @click="createRecord"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#createmodal"
        >
          Create Booking Slot
        </button>
      </div>

      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 ms-1">
              <div class="row d-flex justify-content-center mt-5 ">
                <div class="ms-1">
                  <div
                    class="container"
                    style="max-width:100%; max-height:1000px; overflow:scroll;"
                  >
                    <table class="table table-bordered table-hover">
                      <thead
                        style="position: sticky;top: 0;background-color: white;"
                      >
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="isTableLoading">
                          <tr>
                            <td colspan="6">
                              <Loading />
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <template v-if="bookingSlotList.length > 0">
                            <tr
                              v-for="(data, index) in bookingSlotList"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>{{ data.name }}</td>
                              <td>{{ typeOptions[data.day_type] }}</td>
                              <td>{{ data.start_time }}</td>
                              <td>{{ data.end_time }}</td>

                              <td>
                                <button
                                  v-on:click="editmodel(data)"
                                  class="btn btn-warning me-3 mb-3"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createmodal"
                                >
                                  Edit
                                </button>

                                <button
                                  v-on:click="deleteRecord(data.id)"
                                  class="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </template>
                          <tr v-else>
                            <td colspan="6">No slot record exist!</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },

  setup() {
    return {
      typeOptions: {
        0: 'Weekdays',
        1: 'Weekends'
      },

      defaultForm: {
        name: '',
        day_type: '',
        start_time: '',
        end_time: '',
      },
    };
  },

  data(){
    return{
      
      link: "https://backend.smkedugroup.com",
      isTableLoading: true,
      isSaveBtnLoading: false,
      sort: 'id',
      currentForm: this.defaultForm,
      bookingSlotList: [],
      search: '',
    }
  },

  methods: {
    searchData() {
      if(this.search == "") {
        this.show();
        return;
      }

      this.isTableLoading = true;
      let params = {
        search: this.search.toLowerCase(),
      };

      axios.post(this.link+"/api/searchcalendar", params)
        .then(response => {
          this.bookingSlotList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link+"/api/booking-slots")
        .then(response => {
          this.bookingSlotList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    createRecord() {
      this.currentForm = this.defaultForm;
    },

    store(){
      if(this.currentForm.name == "") {
        return document.getElementById("name").focus();
      }
      if(this.currentForm.day_type == "") {
        return document.getElementById("day_type").focus();
      }
      if(this.currentForm.start_time == "") {
        return document.getElementById("start_time").focus();
      }
      if(this.currentForm.end_time == "") {
        return document.getElementById("end_time").focus();
      }

      let payload = {...this.currentForm};
      this.isSaveBtnLoading = true;

      if (this.currentForm.id) {
        axios.put(this.link + "/api/booking-slots/" + this.currentForm.id, this.currentForm)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      } else {
        axios.post(this.link+"/api/booking-slots", payload)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      }
    },

    editmodel(data) {
      console.log(data);
      this.currentForm = {...data};
    },

    deleteRecord(id){
      let result = confirm("Want to delete?");
      if (result) {
        axios.delete(this.link+"/api/booking-slots/"+id)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },
  },

  async mounted() {
    this.isTableLoading = true;

    try {
      this.show();
    } catch (error) {
      console.log("error mounting admin user page: ", error);
    } finally {
      this.isTableLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
