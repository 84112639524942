<template>
  <div class="mt-5">
    <h2 class="text-start mb-4">
      {{ currentFont.title }}
    </h2>
    <div class="row d-flex justify-content-center">
      <div class="col-md-6">
        <div class="mb-3 text-start">
          <label
            for="parent_name"
            class="form-label"
            >{{ currentFont.parent_name }}</label
          >
          <input
            type="text"
            class="form-control"
            id="parent_name"
            v-model="currentForm.parent_name"
            :placeholder="currentFont.parent_name"
            requried
          />
        </div>
        <div class="mb-3 text-start">
          <label for="email" class="form-label">{{ currentFont.email }}</label>
          <input
            type="text"
            class="form-control"
            id="email"
            v-model="currentForm.email"
            :placeholder="currentFont.email"
          />
        </div>
        <div class="mb-3 text-start">
          <label
            for="booking_date"
            class="form-label"
            >{{ currentFont.booking_date }}</label
          >
          <input
            type="date"
            class="form-control"
            v-model="currentForm.booking_date"
            id="booking_date"
            :placeholder="currentFont.booking_date"
            :min="minDate"
            @change="changeBookingDate"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3 text-start">
          <label
            for="student_name"
            class="form-label"
            >{{ currentFont.student_name }}</label
          >
          <input
            type="text"
            class="form-control"
            id="student_name"
            v-model="currentForm.student_name"
            :placeholder="currentFont.student_name"
          />
        </div>
        <div class="mb-3 text-start">
          <label for="phone" class="form-label">{{ currentFont.phone }}</label>
          <input
            type="text"
            class="form-control"
            v-model="currentForm.phone"
            id="phone"
            :placeholder="currentFont.phone"
            requried
          />
        </div>
        <div class="mb-3 text-start">
          <label
            for="booking_time"
            class="form-label"
            >{{ currentFont.booking_time }}</label
          >
          <input
            type="time"
            class="form-control"
            v-model="currentForm.booking_time"
            id="booking_time"
            :placeholder="currentFont.booking_time"
            required
            disabled
          />
        </div>
      </div>
      <div class="col-md-6" v-if="displayedBookingSlots.length > 0">
        <div class="mb-3 text-start">
          <h4 class="mb-2">Available Booking Slot</h4>

          <ul id="bookingSlots" style="list-style: none">
            <li
              class="form-label d-flex gap-3"
              v-for="slot in displayedBookingSlots"
              :key="slot.id"
            >
              <input
                class="form-check-input"
                v-model="currentForm.booking_slot_id"
                :value="slot.id"
                type="radio"
                name="booking_slot_id"
                :id="'booking_slot_id_' + slot.id"
                @click="changeBookingSlot(slot)"
              />
              <label
                class="form-check-label"
                :for="'booking_slot_id_' + slot.id"
              >
                {{ slot.name }} ({{ slot.start_time }} - {{ slot.end_time }})
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 text-start">
        <span
          v-if="currentForm.booking_slot_id"
          :class="{'text-danger': !isSelectedBookingAvailable, 'text-primary': isSelectedBookingAvailable}"
        >
          <template v-if="isSlotCheckerLoading">Loading...</template>
          <template v-else>
            ** The selected booking slot is
            {{ isSelectedBookingAvailable ? 'Available' : 'Occupied' }}.
          </template>
        </span>
      </div>
      <div class="col-md-12">
        <div class="mb-3 text-start">
          <label for="courses" class="form-label">
            {{ currentFont.courses }}</label
          >

          <div id="courses">
            <div class="form-check" v-for="course in courses" :key="course.id">
              <input
                class="form-check-input"
                :value="course.id"
                v-model="currentForm.courses"
                type="checkbox"
                :id="'course_' + course.id"
              />
              <label
                class="form-check-label d-flex justify-content-start"
                :for="'course_' + course.id"
              >
                {{ course.intake }} <br />
                (Intake - {{ course.fromMonth + ' - ' + course.toMonth}})
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <div class="mb-3 text-start">
          <button
            class="btn btn-warning d-flex justify-content-center align-items-center"
            type="submit"
            @click="submitAppointment"
            :disabled="isSubmitBtnLoading || !isSelectedBookingAvailable"
          >
            <div
              class="spinner-border"
              role="status"
              v-if="isSubmitBtnLoading"
            ></div>
            {{ currentFont.submit }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAxiosClient } from '@/data/axios';
import { getCourses } from '@/services/courseApi';
import axios from 'axios';
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },

  setup() {
    return {
      burmeseFont: {
        title: 'ကြိုတင်ချိန်းဆိုချက်ဖောင်',
        parent_name: 'မိဘအမည်',
        student_name: 'ကျောင်းသား/သူ အမည်',
        email: 'အသုံးပြုသူ အီးမေးလ်',
        phone: 'ဖုန်းနံပါတ်',
        courses: 'စိတ်ဝင်စားသော အတန်းများ (အနည်းဆုံး တစ်ခု ရွေးပေးပါ)',
        booking_date: 'နေ့ရက်',
        booking_time: 'အချိန်',
        submit: 'တင်သွင်းပါ'
      },
      englishFont: {
        title: 'Appointment Form',
        parent_name: 'Parent Name',
        student_name: 'Student Name',
        email: 'Email',
        phone: 'Phone Number',
        courses: 'Interested Courses (Please select at least one course)',
        booking_date: 'Booking Date',
        booking_time: 'Booking Time',
        submit: 'Submit'
      },
      minDate: new Date().toJSON().slice(0, 10),
      isSelectedBookingAvailable: false,
    };
  },

  data() {
    return {
      
      link: "https://backend.smkedugroup.com",
      isSubmitBtnLoading: false,
      isLoading: true,
      isSlotCheckerLoading: true,
      currentFont: this.englishFont,
      currentForm: {
        parent_name: '',
        student_name: '',
        email: '',
        phone: '',
        courses: [],
        booking_date: '',
        booking_time: '',
        booking_slot_id: null,
      },
      courses: [],
      getCourses: getCourses,
      bookingSlots: [],
      displayedBookingSlots: [],
    };
  },

  computed: {
    font_type() {
      let type = this.$store.state.font_type;
      this.currentFont = type == 1 ? this.englishFont : this.burmeseFont;

      return type;
    }
  },

  methods: {
    getBookingSlots() {
      getAxiosClient()
        .get("booking-slots")
        .then((res) => {
          this.bookingSlots = res.data;
        })
        .catch((error) => {
          console.error("error fetching bookging slots: ", error)
        });
    },

    validateFields() {
      if (this.currentForm.parent_name == "") {
        document.getElementById("parent_name").focus();
        return false;
      }
      if (this.currentForm.student_name == "") {
        document.getElementById("student_name").focus();
        return false;
      }
      if (this.currentForm.email === "") {
        document.getElementById("email").focus();
        return false;
      }
      if (this.currentForm.phone === "") {
        document.getElementById("phone").focus();
        return false;
      }
      if (this.currentForm.booking_date == "") {
        document.getElementById("booking_date").focus();
        return false;
      }
      if (this.currentForm.booking_time == "") {
        document.getElementById("booking_time").focus();
        return false;
      }

      return true;
    },

    submitAppointment() {
      if (! this.validateFields()) {
        return;
      }

      this.isSubmitBtnLoading = true;

      let payload = {...this.currentForm};

      axios.post(this.link + '/api/appointments', payload)
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 3000
          });
          window.location.reload()
        })
        .catch(errorRes => {
          Swal.fire({
            icon: 'error',
            title: 'Sorry!',
            text: errorRes.response?.data?.message ?? 'The selected booking slot is already taken',
            confirmButtonText: 'OK',
          });
        })
        .finally(() => this.isSubmitBtnLoading = false);
    },

    changeBookingDate(event) {
      const dateObj = new Date(event.target.value);
      const dateIdx = dateObj.getDay();

      this.displayedBookingSlots = this.bookingSlots.filter(slot => {
        return slot.day_type == (dateIdx >= 1 && dateIdx <= 5 ? 0 : 1);
      });
    },

    changeBookingSlot(slot) {
      this.currentForm.booking_time = slot.start_time;

      this.isSlotCheckerLoading = true;
      axios.get(this.link + '/api/day-slots-checker', {
        params: {
          date: this.currentForm.booking_date,
          booking_slot_id: slot.id
        }
      })
        .then(response => {
          if (response.data.status == "success") {
            this.isSelectedBookingAvailable = !response.data.appointment_status;
          }
        })
        .catch(errorRes => {
          console.log("get appointment response error: ", errorRes)
        })
        .finally(() => this.isSlotCheckerLoading = false);
    }
  },

  async mounted() {
    try {
      this.isLoading = true;

      this.currentFont = this.$store.state.font_type == 1 ? this.englishFont : this.burmeseFont;

      this.courses = await this.getCourses();
      this.getBookingSlots();
    } catch (error) {
      console.error("error loading appointment form component: ", error);
    } finally {
      this.isLoading = false
    }
  },
}
</script>
