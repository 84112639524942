import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import PreSchool from "../views/PreSchool.vue";
import PrimarySchool from "../views/PrimarySchool.vue";
import SecondarySchool from "../views/SecondarySchool.vue";
import Pre from "../views/pre.vue";
import Pri from "../views/pri.vue";
import Sec from "../views/sec.vue";
import AboutSchool from "../views/aboutschool.vue";
import MissionVision from "../views/vision_mission.vue";
import Procedure from "../views/procedure.vue";
import Fees from "../views/admission/Fees.vue";
import AcademicCalendar from "../views/ac_calendar.vue";
import calendar from "../views/calendar.vue";
import AdminAppointmentEnquiry from "../views/AdminAppointmentEnquiry/index.vue";
import FeesRegister from "../views/feesregistry.vue";
import CoursesRegister from "../views/CourseRegistry.vue";
import CoursesRegistryCreate from "../views/CourseRegistryCreate.vue";
import CoursesRegistryEdit from "../views/CourseRegistryEdit.vue"
import teacherlist from "../views/teacherlist.vue";
import TeacherRegister from "../views/teacher_register.vue";
import TeacherCategory from "../views/TeacherCategory.vue";
import about_teacher from "../views/teacherabout.vue";
import Login from "../views/Login.vue";
import logout from "../views/logout.vue";
import Career from "../views/Career.vue";
import CareerView from "../views/CareerView.vue";

import Contactus from "../views/Contactus.vue";
import waitinglist from "../views/waitinglist.vue";
import newsevent from "../views/news_event.vue";
import waitinglistview from "../views/waitinglistview.vue";
import waitinglistdetail from "../views/waitinglistdetail.vue";
import eventregister from "../views/eventregister.vue";
import ContactusView from "../views/ContactusView.vue";
import newpri from "../views/newpri.vue";
import newsec from "../views/newsec.vue";
import parentpolicy from "../views/parentpolicy.vue";
import paymentpolicy from "../views/paymentpolicy.vue";
import pschool from "../views/pschool.vue";
import AboutUs from "@/views/AboutUs.vue";

// admission and sub routes
import AdmissionOverview from "@/views/admission/AdmissionOverview.vue";
import RequiredDocumentation from "@/views/admission/RequiredDocumentation.vue";
import Faq from "@/views/admission/Faq.vue";

// academic and sub routes
import AcademicWeekdayPreSchool from "@/views/academic/weekday/AcademicWeekdayPreSchool.vue";
import AcademicWeekdayPrimary from "@/views/academic/weekday/AcademicWeekdayPrimary.vue";
import AcademicWeekdaySecondary from "@/views/academic/weekday/AcademicWeekdaySecondary.vue";

import AcademicWeekendPreSchool from "@/views/academic/weekend/AcademicWeekendPreSchool.vue";
import AcademicWeekendPrimary from "@/views/academic/weekend/AcademicWeekendPrimary.vue";
import AcademicWeekendSecondary from "@/views/academic/weekend/AcademicWeekendSecondary.vue";
import AdminUser from "@/views/User/index.vue";
import AppointmentEnquiry from "@/views/AppointmentEnquiry/index.vue";
import BookingSlot from "@/views/BookingSlot/index.vue";

const routes = [
  {
    path: "/news-event",
    name: "news-event",
    component: newsevent,
  },
  {
    path: "/pschool",
    name: "pschool",
    component: pschool,
  },
  // ak start
  // {
  //   path: "/about-us",
  //   name: "AboutUs",
  //   component: AboutUs,
  // },
  {
    path: "/admission",
    redirect: "/admission/overview",
    name: "Admission",
    children: [
      {
        path: "overview",
        name: "AdmissionOverview",
        component: AdmissionOverview,
      },
      {
        path: "required-documentation",
        name: "RequiredDocumentation",
        component: RequiredDocumentation,
      },
      {
        path: "fees",
        name: "Fees",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Fees,
      },
      {
        path: "faq",
        name: "Faq",
        component: Faq,
      },
      {
        path: "waiting-list",
        name: "WaitingList",
        component: waitinglist,
      },
    ],
  },
  {
    path: "/academic",
    redirect: "/academic/weekend/primary",
    name: "Academic",
    children: [
      {
        path: "weekday/pre-school",
        name: "AcademicWeekdayPreSchool",
        component: AcademicWeekdayPreSchool,
      },
      {
        path: "weekday/primary",
        name: "AcademicWeekdayPrimary",
        component: AcademicWeekdayPrimary,
      },
      {
        path: "weekday/secondary",
        name: "AcademicWeekdaySecondary",
        component: AcademicWeekdaySecondary,
      },
      {
        path: "weekend/pre-school",
        name: "AcademicWeekendPreSchool",
        component: AcademicWeekendPreSchool,
      },
      {
        path: "weekend/primary",
        name: "AcademicWeekendPrimary",
        component: AcademicWeekendPrimary,
      },
      {
        path: "weekend/secondary",
        name: "AcademicWeekendSecondary",
        component: AcademicWeekendSecondary,
      },
    ],
  },
  {
    path: "/news-and-activities",
    name: "NewsAndActivities",
    component: newsevent,
  },
  // ak end
  {
    path: "/parent-policy",
    name: "ParentPolicy",
    component: parentpolicy,
  },

  {
    path: "/payment-policy",
    name: "PaymentPolicy",
    component: paymentpolicy,
  },

  {
    path: "/admin/event-register",
    name: "EventRegister",
    component: eventregister,
  },
  {
    path: "/admin/career-view",
    name: "CareerView",
    component: CareerView,
  },

  {
    path: "/admin/waiting-list-detail",
    name: "WaitingListDetail",
    component: waitinglistdetail,
  },
  {
    path: "/admin/waiting-list-view",
    name: "waitinglistview",
    component: waitinglistview,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "AboutSchool",
    component: AboutSchool,
  },
  {
    path: "/about-us#examcenter",
    name: "AboutSchool_exam",
    component: AboutSchool,
  },
  {
    path: "/about-us",
    name: "AboutSchool_partner",
    component: AboutSchool,
  },
  {
    path: "/about_teacher",
    name: "about_teacher",
    component: about_teacher,
  },
  {
    path: "/admin/contact-us-view",
    name: "ContactusView",
    component: ContactusView,
  },

  {
    path: "/admin/fees-Register",
    name: "FeesRegister",
    component: FeesRegister,
  },
  {
    path: "/teacher-list",
    name: "teacherlist",
    component: teacherlist,
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: logout,
  },
  {
    path: "/admin/courses-register",
    name: "CoursesRegister",
    component: CoursesRegister,
  },
  {
    path: "/admin/courses-register/create",
    name: "CoursesRegisterCreate",
    component: CoursesRegistryCreate,
  },
  {
    path: "/admin/courses-register/:id/edit",
    props: true,
    name: "CoursesRegisterUpdate",
    component: CoursesRegistryEdit,
  },
  {
    path: "/admin/teacher-register",
    name: "TeacherRegister",
    component: TeacherRegister,
  },
  {
    path: "/admin/teacher-category",
    name: "TeacherCategory",
    component: TeacherCategory,
  },
  {
    path: "/admin/calendar",
    name: "Calendar",
    component: calendar,
  },
  {
    path: "/admin/user",
    name: "AdminUser",
    component: AdminUser,
  },
  {
    path: "/academic-calendar",
    name: "AcademicCalendar",
    component: AcademicCalendar,
  },
  {
    path: "/mission-vision",
    name: "MissionVision",
    component: MissionVision,
  },

  // {
  //   path: '/Admission',
  //   name: 'Procedure',
  //   component: Procedure,
  //   children: [
  //     {
  //       path: 'overview',
  //       name: 'Overview',
  //       component: Overview
  //     }
  //   ]
  // },

  // {
  // 	path: "/Course/PreSchool",
  // 	name: "Pre",
  // 	component: Pre,
  // },

  {
    path: "/Course/PrimarySchool",
    name: "newpri",
    component: newpri,
  },
  {
    path: "/Course/SecondarySchool",
    name: "newsec",
    component: newsec,
  },

  {
    path: "/Course/pri",
    name: "pri",
    component: Pri,
  },

  {
    path: "/Course/sec",
    name: "sec",
    component: Sec,
  },

  {
    path: "/contact-us",
    name: "Contactus",
    component: Contactus,
  },
  // {
  // 	path: "/admission/waiting-list",
  // 	name: "WaitingList",
  // 	component: waitinglist,
  // },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
  },
  {
    path: "/appoinment-enquiry",
    name: "AppointmentEnquiry",
    component: AppointmentEnquiry,
  },
  {
    path: "/admin/appoinment-enquiry-list",
    name: "AdminAppointmentEnquiry",
    component: AdminAppointmentEnquiry,
  },
  {
    path: "/admin/booking-slot-list",
    name: "AdminBookingSlot",
    component: BookingSlot,
  }
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return {
      top: 0,
      behavior: "auto",
    };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
