<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-career"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Career List
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-position"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Add Position
          </button>
        </div>
      </nav>

      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-career"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          <div style="background-color:rgb(244, 234, 223)" class="p-3">
            <div class="container">
              <h1 class="fw-bold">Career List</h1>

              <div class="card mt-5">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 ms-1">
                      <div class="row d-flex justify-content-center mt-5 ">
                        <div class="ms-1">
                          <div
                            class="container"
                            style="max-width:100%; max-height:1000px; overflow:scroll;"
                          >
                            <table class="table table-bordered table-hover">
                              <thead
                                style="position: sticky;top: 0;background-color: white;"
                              >
                                <tr>
                                  <th>No</th>
                                  <th>Name</th>
                                  <th>Studied</th>
                                  <th>Position</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                  <th>About</th>
                                  <th>CV</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                <template v-if="isTableLoading">
                                  <tr>
                                    <td colspan="9">
                                      <Loading />
                                    </td>
                                  </tr>
                                </template>
                                <template v-else>
                                  <template v-if="careerList.length > 0">
                                    <tr v-for="(data, index) in careerList" :key="index">
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ data.name }}</td>
                                      <td>{{ data.studied }}</td>
                                      <td>{{ data.position }}</td>
                                      <td>{{ data.phone }}</td>
                                      <td>{{ data.email }}</td>
                                      <td>{{ data.about }}</td>
                                      <td>
                                        <button
                                          type="button"
                                          class="btn btn-primary me-3"
                                          @click="showcv(data.filename)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#cv"
                                        >
                                          {{ data.filename }}
                                        </button>
                                      </td>
                                      <td>
                                        <button class="btn btn-danger" @click="deleteCareer(data.id)">
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </template>
                                  <tr v-else>
                                    <td colspan="9">No record exist!</td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="cv"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">CV File</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <iframe
                      :src="file"
                      style="width:100%; height:70vh; border:1px; border-style: solid; "
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="nav-position"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
          tabindex="0"
        >
          <div class="row d-flex justify-content-center">
            <div class="col-sm-8">
              <div class="shadow p-5 rounded mb-5">
                <div class="mb-3">
                  <label for="position">Position</label>
                  <input
                    type="text"
                    v-model="position"
                    class="form-control"
                    placeholder="Enter Position"
                    id="position"
                  />

                  <label for="amount">Required Amount</label>
                  <input
                    type="number"
                    v-model="amount"
                    class="form-control"
                    placeholder="Enter Amount"
                    min="0"
                    id="amount"
                  />
                </div>

                <div class="d-flex justify-content-end">
                  <div class="">
                    <button
                      class="btn btn-warning m-2"
                      @click="() => { isPositionedID ? updateposition() : storeposition()}"
                    >
                      {{ isPositionedID ? 'Update' : 'Create' }}
                    </button>
                    <button
                      class="btn btn-danger m-2"
                      v-if="isPositionedID"
                      @click="doCancelPosition()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <table class="table table-border">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Position</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(position, index) in positionlist" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ position.position }}</td>
                    <td>{{ position.amount }}</td>
                    <td>
                      <button
                        class="btn btn-warning me-3"
                        @click="isPositionedID=true; editPosition(position);"
                      >
                        Update
                      </button>
                      <button
                        class="btn btn-danger"
                        @click="deletePosition(position.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
      <!-- Modal -->
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { DeviceUUID } from 'device-uuid';
import Loading from '@/components/general/Loading.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    Loading
  },

  data () {
    return {
      isLoading: true,
      isTableLoading: true,
      cv:'#CV',
      idcv:'CV',
      
      link:"https://backend.smkedugroup.com",
      dat:'',
      careerList:null,
      file:'',
      userID:'',
      localtoken:'',
      deviceID:'',
      position:'',
      amount:'',
      positionlist:null,
      sPositionID:'',
      isPositionedID:false
    }
  },

  methods: {
    doCancelPosition() {
      this.isPositionedID=false
      this.amount = '';
      this.position = '';
      this.sPositionID = '';
    },

    editPosition(dl) {
      this.sPositionID = dl.id;
      this.position = dl.position;
      this.amount = dl.amount;
    },

    storeposition() {
      let formdata = {
        'position':this.position,
        'amount':this.amount
      };

      axios.post(this.link+"/api/storeposition",formdata)
        .then(response =>{
          this.doCancelPosition();
          this.showposition();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    showposition() {
      axios.post(this.link+"/api/showposition")
        .then(response => {
          this.positionlist = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    updateposition() {
      let formdata = {
        'position':this.position,
        'amount':this.amount
      };
      axios.post(this.link+"/api/updateposition/"+this.sPositionID,formdata)
        .then(response =>{
          this.doCancelPosition();
          this.showposition();
          this.isPositionedID=false;
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    deletePosition(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios.post(this.link+"/api/deleteposition/" + id)
          .then(response =>{
            this.showposition();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      }
    },

    showcv(cvname) {
      // this.file = this.link + "/api/eachcv/" + cvname;
      axios.post(this.link + "/api/eachcv/" + cvname)
        .then(response => {
          this.file = response.data.file;
        })
        .catch(error => {
          this.errorMessage = error.message;
          this.file = '';
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'There was an error to load this file.',
            confirmButtonText: 'OK'
          });
          console.error("There was an error!", error);
        });
    },

    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link+"/api/alreadyLogin", article)
        .then(response => {
          if(response.data.state=="failed") {
            window.location.assign("/login")
          } else if(response.data.state=="success") {
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    deleteCareer(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios.delete(this.link+"/api/career/" + id)
          .then(response =>{
            console.log(response);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link+"/api/career")
        .then(response => {
          this.careerList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
  },

  async mounted () {
    this.showposition();
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();
      this.show();
    } catch (error) {
      console.log("error mounting career page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
