<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
      <div
        class="modal fade"
        id="createmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Teacher Category
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-10 ms-3">
                    <h1 class="m-3">Teacher Category</h1>
                    <div class="mt-5 ">
                      <div class="row ">
                        <div class="col-sm-6 ">
                          <div class="mb-3 row">
                            <label
                              for="categoryName"
                              class="col-sm-2 col-form-label"
                              >Name</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                type="text"
                                class="form-control"
                                id="categoryName"
                                v-model="currentForm.name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label
                              for="categoryDescription"
                              class="col-sm-2 col-form-label"
                              >Description</label
                            >
                            <div class="col-sm-8 ms-3">
                              <textarea
                                name=""
                                class="form-control"
                                required
                                cols="30"
                                rows="5"
                                style="resize: none;"
                                id="categoryDescription"
                                v-model="currentForm.description"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-destory"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="d-flex justify-align-center btn btn-primary"
                @click="storeRecord"
              >
                <span
                  id="createbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isSaveBtnLoading"
                ></span>
                <span id="createbtntext" v-if="! isSaveBtnLoading">Save Record</span>
              </button>

              <button
                id="createclose"
                style="visibility: hidden;"
                type="button"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h1 class="fw-bold">Teacher Category List</h1>
        <div class="d-flex justify-content-end mt-4">
          <button
            @click="createRecord"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createmodal"
          >
            Create Category
          </button>
        </div>

        <div class="card mt-5">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <div class="row d-flex justify-content-center mt-5 ">
                  <div class="ms-1">
                    <div
                      class="container"
                      style="max-width:100%; max-height:1000px; overflow:scroll;"
                    >
                      <table class="table table-bordered table-hover">
                        <thead
                          style="position: sticky;top: 0;background-color: white;"
                        >
                          <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-if="isTableLoading">
                            <tr>
                              <td colspan="6">
                                <Loading />
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="categories.length > 0">
                              <tr
                                v-for="(category, index) in categories"
                                :key="category.id"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ category.name }}</td>
                                <td>{{ category.description }}</td>
                                <td>
                                  <button
                                    v-on:click="editRecord(index)"
                                    class="btn btn-warning me-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#createmodal"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    v-on:click="deleteRecord(category.id)"
                                    class="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td colspan="4">
                                No teacher category record exist!
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '@/components/general/Loading.vue';
import { DeviceUUID } from 'device-uuid';

// Create an Axios instance with your base URL and other configurations
const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,  // Set a timeout for the request
});

export default {
  components: {
    Loading
  },

  setup() {
    return {
      defaultForm: {
        id: '',
        name: '',
        description: ''
      },
    };
  },

  data() {
    return {
      
      link: "https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      isSaveBtnLoading: false,
      categories: [],
      currentForm: this.defaultForm,
      userID: '',
      localtoken: '',
      deviceID: ''
    };
  },

  methods: {
    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link + "/api/alreadyLogin", article)
        .then(response =>{
          if(response.data.state == "failed"){
            window.location.assign("/login")
          } else if(response.data.state == "success") {
            this.isSuperAdmin = response.data.isSuperAdmin;
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link + '/api/teacher-category-list')
        .then((response) => {
        console.log(response.data);
        this.categories = response.data;
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        this.isTableLoading = false;
      });
    },

    createRecord() {
      this.currentForm = this.defaultForm;
    },

    editRecord(index) {
      this.currentForm = this.categories[index];
    },

    deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios.delete(`${this.link}/api/teachercategory/${id}`)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },

    storeRecord() {
      if (this.currentForm.name == "") {
        return document.getElementById("name").focus();
      }
      if (this.currentForm.description == "") {
        return document.getElementById("description").focus();
      }

      const payload = {
        ...this.currentForm,
        name: this.currentForm.name,
        description: this.currentForm.description,
      };
      this.isSaveBtnLoading = true;

      if (this.currentForm.id) {
        const url = this.link + '/api/teacher-category-update/' + this.currentForm.id;
        // Use the Axios instance to make the request
        // const response = await apiClient.put(url, payload);
        axios.put(url, payload)
          .then(response => {
            // Show success alert
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Updated',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'There was an error. Please try again.',
              confirmButtonText: 'OK'
            });
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      } else {
        axios.post(this.link + '/api/teachercategory', payload)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error',
              confirmButtonText: 'OK',
            });
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      }
    }
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();

      this.show();
    } catch (error) {
      console.log("error mounting teacher category page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>
