<template>
  <section>
    <div class="py-5">
      <div class="accordion" id="accordionExample">
        <div v-for="category in categories" :key="category.id" class="accordion-item">
          <h2 class="accordion-header" :id="'heading' + category.id">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + category.id" aria-expanded="false" :aria-controls="'collapse' + category.id">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="plus-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="minus-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
              </svg>
              {{ category.name }}
            </button>
          </h2>
          <div :id="'collapse' + category.id" class="accordion-collapse collapse"
            :aria-labelledby="'heading' + category.id" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="avice_color" style="color: white">
                <div class="row gx-3 gy-3 p-4">
                  <div class="col-12" v-if="category.teachers?.length > 0">
                    <div class="teacher-content py-4" :style="index != category.teachers.length - 1 ? 'border-bottom: 1px solid #86b7fe;' : ''" v-for="(teacher, index) in category.teachers" :key="teacher.id">
                      <!-- <div class="teacher-info">
                        <div class="clear-fix">
                          <div class="teacher-image">
                            <img :src="link + '/schooldata/teacherphoto/' + teacher.teacher_photo_path" :alt="teacher.name" />
                          </div>
                          <p style="text-align: start; line-height: 30px" v-html="teacher.message"></p>
                        </div>
                      </div> -->
                      <div class="d-flex">
                        <div class="teacher-image">
                          <img :src="link + '/schooldata/teacherphoto/' + teacher.teacher_photo_path" :alt="teacher.name" />
                        </div>
                        <p style="text-align: start; line-height: 30px; flex-grow: 0" v-html="teacher.message"></p>
                      </div>
                      <p style="text-align: start; line-height: 30px" v-html="teacher.name"></p>
                      <p style="text-align: start; line-height: 30px" v-html="teacher.position"></p>
                      <p style="text-align: start; line-height: 30px" v-html="teacher.studied"></p>
                      <div v-if="teacher.logos?.length > 0" class="mt-5 text-center">
                        <h5 class="teacher-logo-title">
                          British council credential
                        </h5>
                        <img v-for="logo in teacher.logos" :key="logo" class="teacher-logo" :src="require(`@/assets/img/aboutus-teachers/logos/${logo}`)
                          " alt="" />
                      <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/pro-dev.png" alt="">
                        <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/lead-dev.png" alt="">
                        <img class="teacher-logo" src="@/assets/img/aboutus-teachers/logos/expert-edu-starter-30.png"
                          alt="">
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    No teacher yet.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      
      link: "https://backend.smkedugroup.com",
      categories: [],
    };
  },
  mounted() {
    this.fetchTeachers();
  },
  methods: {
    async fetchTeachers() {
      try {
        const response = await axios.get(this.link + "/api/teacher-category-list");
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching teacher and categories:", error);
      }
    },
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher-image {
  display: inline-block;
  border: 1px;
  border-style: solid;
  min-width: 250px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;

  img {
    width: 100%;
    max-height: 320px;
    object-fit: cover;
  }
}

.teacher-info {
  min-height: 260px;
}

.teacher-content {
  text-align: justify;
  min-height: 250px;
}

.clear-fix {
  clear: both;
}

.plus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.minus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.accordion-button {
  text-transform: uppercase;
}

.accordion-button:not(.collapsed) .minus-icon {
  display: inline-block;
}

.accordion-button.collapsed .plus-icon {
  display: inline-block;
}

.accordion-button::after {
  display: none !important;
}

.accordion-body {
  padding: 0;
}

.avice_color {
  background: rgb(55, 0, 58);
  background: linear-gradient(355deg,
      rgba(55, 0, 58, 1) 0%,
      rgba(77, 0, 82, 1) 49%,
      rgba(113, 0, 120, 1) 100%);
}

.teacher-logo-title {
  text-transform: capitalize;
}

.teacher-logo {
  width: 150px;
  height: 150px;
}
</style>
