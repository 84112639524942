<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color: rgb(244, 234, 223)" class="p-3">
      <div
        class="modal fade"
        id="createmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Fees Register
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-10 ms-3">
                    <h1 class="m-3">Fees Register</h1>
                    <div class="mt-5">
                      <div class="row">
                        <div class="col">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >From Year
                            </label>
                            <div class="col-sm-8 ms-3">
                              <input
                                name=""
                                class="form-control"
                                v-model="fromYear"
                                id="fromYear"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >To Year</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                name=""
                                class="form-control"
                                v-model="toYear"
                                required
                                id="toYear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Class Type
                            </label>
                            <div class="col-sm-8 ms-3">
                              <select
                                name=""
                                class="form-select"
                                v-model="classtype"
                                id="classtype"
                              >
                                <option value="1">Weekday class</option>
                                <option value="2">Weekend class</option>
                                <option value="3">Summer class</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Fee Type</label
                            >
                            <div class="col-sm-8 ms-3">
                              <select
                                class="form-select"
                                v-model="feetype"
                                id="feetype"
                              >
                                <option value="1">pre-school</option>
                                <option value="2">primary school</option>
                                <option value="3">secondary</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Charges</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                name=""
                                class="form-control"
                                v-model="charges"
                                required
                                id="charges"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-destory"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="d-flex justify-align-center btn btn-primary"
                @click="store"
              >
                <span
                  id="createbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isSaveBtnLoading"
                ></span>
                <span id="createbtntext" v-if="! isSaveBtnLoading"
                  >Save Record</span
                >
              </button>

              <button
                id="createclose"
                style="visibility: hidden"
                type="button"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="changeyear"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabelYear"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabelYear">
                Change Year
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-10 ms-3">
                    <div class="mt-5 row">
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Year
                          </label>
                          <div class="col-sm-8">
                            <input
                              name=""
                              class="form-control"
                              v-model="year"
                              id="year"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="d-flex">
                          <input
                            name=""
                            class="form-control"
                            v-model="userInputList"
                            id="userInputList"
                            placeholder="list"
                          />
                          <button class="btn btn-primary" @click="addList">
                            add
                          </button>
                        </div>
                        <div
                          v-if="list?.length > 0"
                          class="d-flex flex-column mt-2"
                          style="height: 150px; overflow-y: scroll"
                        >
                          <div
                            class="d-flex my-1"
                            v-for="(item, index) in list"
                            :key="index"
                          >
                            <input
                              type="text"
                              name=""
                              class="form-control"
                              :value="item.name"
                              disabled
                              id=""
                            />
                            <button
                              class="btn btn-danger"
                              @click="deleteList(index)"
                            >
                              delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-destory"
                data-bs-dismiss="modal"
                id="closemodel"
              >
                Close
              </button>
              <button
                class="d-flex justify-align-center btn btn-primary"
                @click="updateYear"
              >
                <span id="createbtntext"> Save Record</span>
              </button>

              <button
                id="createclose"
                style="visibility: hidden"
                type="button"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h1 class="fw-bold">Fees Register</h1>
        <div class="d-flex justify-content-end mt-4 gap-3">
          <button
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createmodal"
          >
            Create Fees
          </button>
          <button
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#changeyear"
          >
            Change Year
          </button>
        </div>

        <div class="card mt-5">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <!-- <div class="col-sm-3 ms-3">
                <div class="mb-3 row">
                  <label for="inputPassword" class="col-sm-4 col-form-label">Sort by</label>
                  <div class="col-sm-6 ms-3">
                    <select class="form-select" v-model="sort" aria-label="Default select example">
                      <option value="1">By ID</option>
                      <option value="2">By Name</option>
                      <option value="3">By Date</option>
                    </select>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3 ms-3">
                  <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">Filter by</label>
                    <div class="col-sm-6 ms-3">
                      <select class="form-select" aria-label="Default select example">
                        <option selected>Filter By</option>
                        <option value="1">By Name</option>
                        <option value="2">By Month</option>
                        <option value="3">By Year</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 ms-3">
                  <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-4 col-form-label">Search by</label>
                    <div class="col-sm-6 ms-1">
                      <input  type="text" class="form-control"   id="color">
                    </div>
                  </div>
                </div>
              -->

                <div class="row d-flex justify-content-center mt-5 ">
                  <div class="ms-1">
                    <div
                      class="container"
                      style="max-width:100%; max-height:1000px; overflow:scroll;"
                    >
                      <table class="table table-bordered table-hover">
                        <thead
                          style="position: sticky;top: 0;background-color: white;"
                        >
                          <tr>
                            <th>No</th>
                            <th>From Year</th>
                            <th>To Year</th>
                            <th>Class Type</th>
                            <th>Feetypes</th>
                            <th>Charges</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-if="isTableLoading">
                            <tr>
                              <td colspan="7">
                                <Loading />
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="feeList.length > 0">
                              <tr v-for="(fee, index) in feeList" :key="fee.id">
                                <td>{{ ++index }}</td>
                                <td>{{ fee.from_year }}</td>
                                <td>{{ fee.to_year }}</td>
                                <td>
                                  <span v-if="fee.class_types == 1"
                                    >WeekDay Class</span
                                  >
                                  <span v-if="fee.class_types == 2"
                                    >WeekEnd Class</span
                                  >
                                  <span v-if="fee.class_types == 3"
                                    >Summer Class</span
                                  >
                                </td>
                                <td>
                                  <span v-if="fee.fees_types == 1"
                                    >Pre-School</span
                                  >
                                  <span v-if="fee.fees_types == 2"
                                    >Primary School</span
                                  >
                                  <span v-if="fee.fees_types == 3"
                                    >Secondary School</span
                                  >
                                  <span v-if="fee.fees_types == 4"
                                    >Extra Course</span
                                  >
                                </td>
                                <td>{{ fee.charges }}</td>
                                <td>
                                  <button
                                    v-on:click="editmodel(fee.id)"
                                    class="btn btn-warning me-3 mb-3"
                                    data-bs-toggle="modal"
                                    :data-bs-target="'#staticBackdrop' + fee.id"
                                  >
                                    Edit
                                  </button>

                                  <button
                                    v-on:click="deleteRecord(fee.id)"
                                    class="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td colspan="7">No fee record exist!</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form v-for="fee in feeList" :key="fee.id">
          <div
            class="modal fade"
            :id="'staticBackdrop' + fee.id"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            :aria-labelledby="'staticBackdropLabelUpdate' + fee.id"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title"
                    :id="'staticBackdropLabelUpdate' + fee.id"
                  >
                    Modal title
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="">
                    <div class="row">
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >From Year
                          </label>
                          <div class="col-sm-8 ms-3">
                            <input
                              name=""
                              class="form-control"
                              v-model="uFromYear"
                              id="uFromYear"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >To Year</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              name=""
                              class="form-control"
                              v-model="uToYear"
                              required
                              id="utoYear"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <!-- feetype: "",
        className: "",
        classtype: "",
        charges: "", -->
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Class Type
                          </label>
                          <div class="col-sm-8 ms-3">
                            <select
                              name=""
                              class="form-select"
                              v-model="uClasstype"
                              id="uClasstype"
                            >
                              <option value="1">Weekday class</option>
                              <option value="2">Weekend class</option>
                              <option value="3">Summer class</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Fee Type</label
                          >
                          <div class="col-sm-8 ms-3">
                            <select
                              class="form-select"
                              v-model="ufeetype"
                              id="ufeetype"
                            >
                              <option value="1">pre-school</option>
                              <option value="2">primary school</option>
                              <option value="3">secondary</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"></div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Charges</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              name=""
                              class="form-control"
                              v-model="ucharges"
                              required
                              id="ucharges"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-destory"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    v-on:click="update(edid)"
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-warning"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { DeviceUUID } from "device-uuid";
import { updateYear, getYear } from "@/services/year.js";
import { getFees, createFees, updateFees, deleteFees } from "@/services/fee.js";
import Loading from "@/components/general/Loading.vue";

export default {
  components: {
    Loading
  },
  data() {
    return {
      
      link:"https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      isSaveBtnLoading: false,
      sort: "id",

      feetype: "",
      fromYear: "",
      toYear: "",

      classtype: "",
      charges: "",

      ufeetype: "",
      uFromYear: "",
      uToYear: "",

      uClasstype: "",
      ucharges: "",

      year: "",
      userInputList: "",
      list: [],

      edid: "",

      articleId: "",
      feeList: [],
      userID: "",
      localtoken: "",
      deviceID: "",
    };
  },

  methods: {
    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link+"/api/alreadyLogin", article)
        .then(response => {
          if(response.data.state == "failed"){
            window.location.assign("/login")
          }
          else if(response.data.state == "success") {
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    async updateYear() {
      let article = {};
      if (this.year == "") return document.getElementById("year").focus();

      article.year = this.year;
      article.discription = this.list;
      try {
        let res = await updateYear(article);
      } catch (error) {
        console.log(error);
      } finally {
        $("#closemodel").click();
      }
    },

    addList() {
      this.list.push({
        name: this.userInputList,
      });
      this.userInputList = "";
    },

    deleteList(id) {
      this.list.splice(id, 1);
    },

    async show() {
      this.isTableLoading = true;

      try {
        let res = await getFees();
        this.feeList = [...res];
        console.log(res);
        let yearRes = await getYear();
        console.log('year res', yearRes)
        this.year = yearRes.data.year;
        this.list = yearRes.data.discription;
      } catch (error) {
        console.log(error);
      } finally {
        this.isTableLoading = false;
      }
    },

    async store() {
      if (this.fromYear == "") {
        return document.getElementById("fromYear").focus();
      }
      if (this.toYear == "") {
        return document.getElementById("toYear").focus();
      }

      if (this.feetype == "") {
        return document.getElementById("feetype").focus();
      }
      if (this.classtype == "") {
        return document.getElementById("classtype").focus();
      } else if (this.charges == "") {
        return document.getElementById("charges").focus();
      }

      let article = {
        feestype: this.feetype,

        fromyear: this.fromYear,
        toyear: this.toYear,

        classtype: this.classtype,

        charges: this.charges,
      };
      this.isSaveBtnLoading = true;

      try {
        let res = await createFees(article);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Success",
          customClass: "sweetalertsuccess",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.show();
        this.isSaveBtnLoading = false;

        $("#createclose").click();
        this.feetype = "";
        this.yeartype = "";
        this.toyear = "";
        this.fromyear = "";
        this.classtype = "";
        this.extratype = "";
        this.charges = "";
        this.remark = "";
      }
    },

    async update(id) {
      // alert(id);
      let article = {
        feestype: this.ufeetype,
        fromyear: this.uFromYear,
        toyear: this.uToYear,
        charges: this.ucharges,

        classtype: this.uClasstype,
      };
      this.isSaveBtnLoading = true;

      try {
        let res = await updateFees(id, article);
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Success",
          customClass: "sweetalertsuccess",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.show();
        this.isSaveBtnLoading = false;
      }
    },

    editmodel(id) {
      this.edid = id;

      if (this.feeList?.length > 0) {
        for (let a = 0; a < this.feeList.length; a++) {
          if (this.feeList[a].id == this.edid) {
            console.log(this.feeList[a]);

            this.ufeetype = this.feeList[a].fees_types;

            this.uFromYear = this.feeList[a].from_year;
            this.uToYear = this.feeList[a].to_year;
            this.ucharges = this.feeList[a].charges;

            this.uClasstype = this.feeList[a].class_types;
          }
        }
      }
      // this.ufee_change();
    },

    async deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        try {
          let res = await deleteFees(id);
          console.log(res);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success",
            customClass: "sweetalertsuccess",
            showConfirmButton: false,
            timer: 1500,
          });

        } catch (error) {
          console.log(error);
        } finally {
          await this.show()
        }
      }
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();
      this.show();
    } catch (error) {
      console.log("error mounting fees page: ", error);
    } finally {
      console.log('is loading: ', this.isLoading)
      this.isLoading = false;
      console.log('is table loading: ', this.isLoading)
    }
  },
};
</script>

<style lang="scss" scoped></style>
